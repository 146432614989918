.AuditRange {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AuditRange-buttons-column {
    width: 75vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:flex-start;
}

.AuditRange-button-row {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.AuditRange-button-text {
    padding: 2px;
    font-size: 1.25em;
}

.AuditRange-standards-list {
    margin-top: 10px;
    padding: 20px;
    border: 1px solid lightgrey;
    border-radius: 20px;
    margin-bottom: 60px;
}

.AuditRange-standard-table {
    width: 60vw;
    display: grid;
    grid-template-columns: 20% 50% 28% 2%;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.AuditRange-additional-info-and-requirements {
    border-bottom: 1px solid lightgrey;
    width: 60vw;
    margin-bottom: 20px;
    height: 0px;
}

.AuditRange-button-container {
    width: 2.5em;
    height: 2.5em;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.AuditRange-button-container:hover {
    background-color: rgb(218, 218, 218);
}