.Dashboard {
    width: 100vw;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}

.Dashboard-header {
    border-radius: 5px;
    margin: 20px;
    margin-left: 60px;
    color: black;
    font-weight: bold;
    font-size: 1.25em;
    margin-bottom: 40px;
}

.Dashboard-card-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;  /*switched from space-between*/
}

.Dashboard-cards {
    height: 100%;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.Dashboard-summary-stats {
    height: 100%;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.Dashboard-arrow-container {
    width: 10vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Dashboard-add-assignment-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.15em;
    text-decoration: none;
    margin-left: 50px;
}

.Dashboard-link-text {
    width: 100%;
    color: rgb(6, 26, 101);
}

.Dashboard-button-index-container {
    width: 1.5em;
    height: 1.5em;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.Dashboard-button-index-container:hover {
    background-color: rgb(218, 218, 218);
}

.Dashboard-button-container {
    width: 2.5em;
    height: 2.5em;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.Dashboard-button-container:hover {
    background-color: rgb(218, 218, 218);
}

.Dashboard-add-assignment-link {
    border: none;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Dashboard-link-text {
    padding: 5px;
}

.Dashboard-loading {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}