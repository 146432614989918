.LandingPage {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.LandingPage-header {
    width: 100vw;
    border-radius: 5px;
    margin-left: 120px;
    padding: 20px;
    padding-bottom: 5px;
    color: black;
    font-weight: bold;
    font-size: 1.25em;
}

.LandingPage-selectors {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.LandingPage-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.LandingPage-selector-name {
    width: 50px;
    font-weight: 600;
    margin: 10px;
}

.LandingPage-select-family-hint {
    width: 100vw;
    margin-left: 120px;
    padding: 20px;
    padding-top: 10px;
    color: black
}

.LandingPage-query-card {
    width: 100vw;
    border-radius: 5px;
    margin-left: 100px;
}