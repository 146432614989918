.StatusSelector {
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px;
}

.StatusSelector-select-container {
    height: 45px;
    max-width: 20vw;
}
