.DashboardCard-container {
    width: 28vw;
    height: 100%;
    border-radius: 20px;
    box-shadow: -2px 5px 20px -10px rgb(39, 39, 39);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
}

.DashboardCard-row-container {
    width: 25vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin:20px;
    margin-bottom: 5px;
}

.DashboardCard-header-container {
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.DashboardCard-subheader-container {
    color: grey;
    font-size: 0.9em;
}

.DashboardCard-edit-link {
    background-color:  transparent;
    color: rgb(6, 26, 101);
    font-size: 0.85em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 3px;
    border-radius: 50px;
    border: none;
    margin-bottom: 5px;
}

.DashboardCard-edit-link-text {
    padding-right: 5px;
}

.DashboardCard-button-edit-container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.DashboardCard-edit-link:hover {
    background-color: rgb(19, 48, 154);
    color: white
}

