.EditUserAssignments-button-edit-container {
    background-color: transparent;
    font-size: 1.25em;
    color: rgb(109, 109, 109);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.EditUserAssignments-button-edit-container:hover {
    background-color: rgb(232, 232, 232);
}

.EditUserAssignment-pop-up {
    height: 300px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid lightgrey;
    z-index: 1;
    top: 35vh;
    left: 30vw;
    position: absolute;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
}

.EditUserAssignment-pop-up-close-icon {
    z-index: 2;
    position: absolute;
    right: -20px;
    top: -20px;
    background-color: lightgrey;
    width: .85em;
    height: .85em;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    padding: 5px;
    border-radius: 50%;
}

.EditUserAssignment-pop-up-close-icon:hover {
    background-color: rgb(219, 219, 219);
}