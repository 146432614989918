.StandardsList-container {
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    border: 1px solid lightgrey;
    border-radius: 20px;
    margin-top: 40px;
    padding: 20px;
    margin-bottom: 60px;
}

.StandardsList-header  {
    padding-top: 20px;
    color: black;
    margin-bottom: 20px;
}

.StandardsList-displayAll {
    width: 60vw;
}

.StandardsList-standard-container {
    display: grid;
    grid-template-columns: 20% 77% 2%;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.StandardsList-number-container {
    font-size: 1em;
}

.StandardsList-title-container {
    font-size: 1em;
}

.StandardsList-view-more-button {
    width: 2em;
    height: 2em;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    border-radius: 50%;
}

.StandardsList-view-more-button:hover {
    background-color: rgb(218, 218, 218);
}

.StandardsList-no-standards {
    width: 80vw;
    padding: 5px;
    font-weight: 550;
}

.StandardsList-details-and-requirements-container {
    border-bottom: 1px solid lightgrey;
    width: 60vw;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}