.Login-button {
    margin: 10px;
    background-color: transparent;
    border: none;
    /* border: 1px solid rgb(227, 227, 227); */
    padding: 8px;
    border-radius: 4px;
    font-size: 0.85em;
    color: rgb(227, 227, 227);
    font-weight: bold;
    cursor: pointer;
}

.Login-button:hover {
    color: white;
    font-weight: bold;
}