.EnterID-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.EnterID {
    width: 100%;
    height: 80%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
    margin-bottom: 40px
}


.Enter-ID-assignment-review {
    width: 25%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    z-index: 2;
}

.Enter-ID-container-right {
    width: 70%;
    height: 90%;
    border-left: 1px solid grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Enter-ID-body {
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.EnterID-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px;
}

.EnterID-container-and-response {
    width: 400px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
}

.EnterID-column-container {
    max-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    margin: 5px;
    overflow-y: scroll;
}

.EnterID-text {
    font-size: 1em;
    font-weight: bold;
    margin-right: 10px;
    color: rgb(6, 26, 101);
}

.EnterID-input {
    width: fit-content;
    font-size: 1em;
    border: 1px solid rgb(198, 198, 199);
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
}

.EnterID-input:focus {
    outline: none;
    border: 1px solid rgb(139, 142, 152);
}

.EnterID-button {
    font-size: 1em;
    background-color: rgb(6, 26, 101);
    border-radius: 50%;
    font-weight: bolder;
    border: none;
    color: white;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.EnterID-button:hover {
    background-color: rgb(19, 48, 154);
}

.item-container {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-top: 5px;
    border-radius: 2px;
    padding: 5px;
    cursor: pointer
}

.item-container:hover {
    background-color: rgb(225, 225, 225);
}

.error-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px;
    color: red
}

.Add-assignment-button-row {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.Add-assignment-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.Add-assignment-button-container {
    font-size: 1em;
    color: white;
    background-color: rgb(6, 26, 101);
    padding: 6px;
    border-radius: 4px;
    text-decoration: none;
    border: none;
    margin: 10px;
    cursor: pointer
}

.Add-assignment-button-container:hover {
    background-color: rgb(19, 48, 154);
    cursor: pointer
}

.EnterID-skip-audit-entry {
    font-size: 1em;
    border:none;
    background-color: transparent;
    text-decoration: underline;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    text-decoration: none;
    color: rgb(102, 102, 102);
    padding: 10px;
}

.EnterID-error-text {
    color: red;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px
}

.Enter-ID-step-number {
    align-items: flex-start;
    color:rgb(95, 95, 95);
    margin-right: 10px;
}