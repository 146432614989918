.SelectRole {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.SelectRole-checkbox-row {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: flex-start;
    padding-right: 15px;
}