.SummaryStats-container {
    width: 48vw;
    height: 100%;
    border-radius: 20px;
    box-shadow: 2px 5px 20px -10px rgb(39, 39, 39);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
}

.SummaryStats-row-container {
    width: 45vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.SummaryStats-header-container {
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    margin-right: 10px;
}

.SummaryStats-subheader-container {
    color: rgb(77, 77, 77);
    font-size: 0.95em;
    overflow-x: hidden;
    margin-right: 10px;
}

.SummaryStats-edit-link {
    width: 100%;
    color: rgb(6, 26, 101);
    font-size: 0.85em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
}

.SummaryStats-button-edit-container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    padding: 5px;
    border-radius: 50%;
}

.SummaryStats-button-edit-container:hover {
    background-color: rgb(232, 232, 232);
}

.SummaryStats-applicability-table-container {
    width: 45vw;
    padding-bottom: 20px;
}

.SummaryStats-applicability-table-header {
    width: 100%;
    display: grid;
    grid-template-columns: 12% 14% 18% 16% 8% 9% 12% 8%;
    align-items: flex-end;
    margin-bottom: 10px;
}

.SummaryStats-applicability-table {
    width: 100%;
    display: grid;
    grid-template-columns: 12% 14% 18% 16% 8% 9% 12% 8%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 50px;
}

.SummaryStats-applicability-table:hover {
    background-color: rgb(225, 228, 234);
}

.SummaryStats-table-text {
    font-size: 0.85em;
    position: relative;
}

.SummaryStats-table-text-alert {
    font-size: 0.85em;
    position: relative;
}

.SummaryStats-family-name-hint {
    background-color: rgb(225, 228, 234);
    position: absolute;
    z-index: 3;
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    top: -35px;
    left: 45px;
    white-space: nowrap;
    padding: 8px;
    padding-right: 10px;
    padding-left: 10px;
}

.SummaryStats-loading {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.SummaryStats-nav-to-assignments {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
}

.SummaryStats-audit-range {
    color: rgb(111, 111, 111);
    white-space: nowrap;
}
