.CompanyInfo {
    width: 80vw;
    margin-bottom: 20px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.CompanyInfo-subheader-row {
    width: 80vw;
    margin-bottom: 0px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.CompanyInfo-subheader-text {
    font-size: 1em;
    color: rgb(109, 109, 109);
}

.CompanyInfo-container {
    border-top: 1px solid rgb(145, 145, 145);
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.CompanyInfo-table-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.CompanyInfo-table-header {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.CompanyInfo-table {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.CompanyInfo-table-response {
    margin: 5px;
    margin-left: 0px;
}

.CompanyInfo-table-label {
    margin: 5px;
    margin-left: 0px;
    color: rgb(109, 109, 109);
}