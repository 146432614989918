.UserInfo {
    width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.UserInfo-header-row {
    width: 80vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
}

.UserInfo-header-text {
    font-size: 1.25em;
    font-weight: bold;
    margin-right: 10px;
}

.UserInfo-subheader-row {
    width: 80vw;
    margin-bottom: 0px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.UserInfo-subheader-text {
    font-size: 1em;
    color: rgb(109, 109, 109);
}

.UserInfo-container {
    border-top: 1px solid rgb(145, 145, 145);
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.UserInfo-table-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.UserInfo-table-header {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.UserInfo-table {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.UserInfo-table-response {
    margin: 5px;
    margin-left: 0px;
}

.UserInfo-table-label {
    margin: 5px;
    margin-left: 0px;
    color: rgb(109, 109, 109);
}

.UserInfo-user-picture-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.UserInfo-user-picture {
    border-radius: 50%;
    margin: 40px;
    width: 15vh;
}