.PartsList-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
}

.Part-header {
    font-size: 1em;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px;
}

.subPart-header {
    font-size: 1em;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px;
    margin-left: 16px;
}

.Part-language-container {
    font-size: 1em;
    padding-left: 8px;
    color: black;
    font-weight: normal;
}
