.UserAssignment-background {
    width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.UserAssignment-table {
    width: 80vw;
    display: grid;
    grid-template-columns: 24% 14% 12% 16% 30% 2%;
    margin-top: 20px;
    font-size: 1em;
}

.UserAssignment-table-header {
    background-color: transparent;
    border-bottom: 2px solid  lightgrey;
    width: 80vw;
    display: grid;
    grid-template-columns: 24% 14% 15% 16% 30% 2%;
    margin-top: 20px;
}

.UserAssignment-container-label {
    margin: 15px;
    color: rgb(6, 26, 101);
    font-weight: bold;
}

.UserAssignment-container-response {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.UserAssignment-no-response {
    margin: 40px;
}

.UserAssignment-button-container {
    width: 2.5em;
    height: 2.5em;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.UserAssignment-button-container:hover {
    background-color: lightgrey;
}

