.DeltaStandards {
    width: 45vw;
    padding-bottom: 20px;
}

.DeltaStandards-header {
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    margin-right: 10px;
    font-weight: 550;
}

.DeltaStandards-table {
    width: 100%;
    font-size: 0.85em;
    display: grid;
    grid-template-columns: 15% 85%;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 50px;
}

.DeltaStandards-length {
    padding-left: 3px;
}

.DeltaStandards-number {
    padding-left: 10px;
}

.DeltaStandards-notes {

}

.DeltaStandards-button-row {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}