.SignUp-button {
    margin: 10px;
    margin-right: 20px;
    background-color: transparent;
    color: white;
    border: 1px solid rgb(227, 227, 227);
    padding: 8px;
    border-radius: 50px;
    font-size: 0.85em;
    font-weight: bold;
    cursor: pointer;
}

.SignUp-button:hover {
    background-color:white;
    color: black
}