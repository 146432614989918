.Requirements {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px;
    margin-top: 30px;
}

.Requirements-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    padding: 5px;
}

.Requirements-row-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Requirements-table-header {
    background-color: transparent;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 65% 2%;
    margin-top: 20px;
    padding: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid  rgb(218, 218, 218);
    font-weight: bold;
}

.Requirements-table {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 65% 2%;
    padding: 5px;
}

.Requirements-header {
    font-size: 1em;
    color: black;
}

.Requirements-language {
    font-size: 1em;
    padding-left: 10px;
}

.Requirements-violations-names{
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.Requirements-violation-level-name {
    font-size: 1em;
    font-weight: bold;
    padding: 10px;
}

.Requirements-violations {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    background-color: rgb(242, 244, 246);
    margin-bottom: 20px;
}

.Requirements-violation-level {
    font-size: 0.75em;
    padding: 10px;
}

.Requirements-view-more-button {
    width: 2em;
    height: 2em;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 5px;
    border-radius: 50%;
}

.Requirements-view-more-button:hover {
    background-color: rgb(218, 218, 218);
}