.AdditionalInformation-subject {
    width: 100%;
    padding: 15px;
    font-size: 1em;
    color:  black;
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: flex-start;
}

.AdditionalInformation-subject-content {
    font-size: 1em;
    color: black;
    font-weight: normal;
    padding-left: 10px;
}