.AddUserAssignments {
    width: 80vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
}

.AddUserAssignments-header-text {
    font-size: 1.25em;
    font-weight: bold;
    margin-right: 10px;
}

.AddUserAssignments-add-icon {
    background-color: transparent;
    color: rgb(6, 26, 101);
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.AddUserAssignments-add-icon:hover {
    color: rgb(19, 48, 154);
}

.AddUserAssignments-add-item {
    width: 80vw;
    height: 0px;
    opacity: 0;
    border: none;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.AddUserAssignments-add-item-container {
    margin: 10px;
}

.AddUserAssignments-add-item-search-by-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
}

.AddUserAssignments-button-row {
    background-color: transparent;
    border-radius: 5px;
    padding: 10px;
}

.AddUserAssignments-add-item-button {
    background-color: transparent;
    border: none;
    padding: 2px;
    margin: 5px;
    cursor: pointer;
}