.Profile {
    width: 100vw;
    min-height: 90vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.Profile-container {
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.Profile-element {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 60px;
}

.Profile-user-assignments {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.ProfileNav {
    box-shadow: 0px 0px 15px -4px rgb(134, 134, 134);
    height: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.ProfileNav-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.ProfileNav-header {
    width: 100%;
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 30px;
}

.ProfileNav-button {
    background-color: transparent;
    width: 150px;
    border: none;
    padding: 5px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: 0.90em;
    text-decoration: none;
    color: black
}