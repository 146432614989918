.Header {
    width: 100%;
    height: 60px;
    background-color: rgb(34, 34, 34);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.header-logo {
    color: white;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: bold;
    font-size: 1.25em;
}

.header-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.header-nav-container-user {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.header-nav-link {
    color: rgb(227, 227, 227);
    padding-right: 20px;
    text-decoration: none;
    font-size: 0.85em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.header-nav-link:hover {
    color: white;
}

.header-user-picture {
    height: 30px;
    width: 30px;
    padding: 1px;
    border-radius: 50%;
    background-color: rgb(227, 227, 227);
}

.header-user-settings {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: -6px;
}